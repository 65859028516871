//Function for toggling mobile menu
const mobileMenu = document.getElementById("mobile-menu");
const navIcon = document.getElementById("nav-icon");

const mobileMenuToggle = document.getElementById("mobile-menu-toggle");

function toggleMobileMenu() {
  navIcon.classList.toggle("is-active");
  mobileMenu.classList.toggle("active");
  mobileMenuToggle.blur();
}
function toggleMobileMenuKeyboard(event) {
  if (event.code === "Enter" || event.code === "Space") {
    navIcon.classList.toggle("is-active");
    mobileMenu.classList.toggle("active");
  }
}

mobileMenuToggle.addEventListener("click", toggleMobileMenu);
mobileMenuToggle.addEventListener("keydown", toggleMobileMenuKeyboard);

//Adding arrow-button to menu items that has a sub menu
var menuItemElements = document.querySelectorAll(".menu-item-has-children");
menuItemElements.forEach((menuItemElement) => {
  let anchorTag = menuItemElement.querySelector("a");
  let textContent = anchorTag.textContent;
  let menuArrowElement = document.createElement("button");
  menuArrowElement.className = "menu-arrow";

  //Adding aria-label for each button
  menuArrowElement.setAttribute("aria-label", "Öppna undermeny för " + textContent);
  menuItemElement.prepend(menuArrowElement);
});

// menuItemElements.forEach((menuitem) => {});

//Making arrows in menu clickable to fold out sub menu
const allMenuItemsWithArrow = document.querySelectorAll(".menu-arrow");

function addClassToParent(event) {
  const clickedElement = event.target;
  const listItem = clickedElement.parentNode;

  if (listItem) {
    if (listItem.classList.contains("is-active")) {
      listItem.classList.remove("is-active");
    } else {
      listItem.classList.add("is-active");
    }
  }
}

allMenuItemsWithArrow.forEach((menuItem) => {
  menuItem.addEventListener("click", addClassToParent);
});
